import ProFaq from "../../components/Accordion/ProFaq";
import ProContact from "../../components/Contacts/ProContact";

export default function Faq(){
return(
    <>
        <ProFaq/>
        <ProContact/>
    </>
)
}