
import "./services.css"
export default function ProServices() {
    const servicelist = [
        {
            label: "Site Empresarial (One page)",
            description:"Site rápido e responsivo que reflete sua visão e atrai seus clientes ideais",
            pricing:"750,00",
            time: "72h"
        },
        {
            label: "Landing Pages",
            description:"Paginas de conversão com copywriting para produto ou serviços",
            pricing:"500,00",
            time: "72h"
        },
        {
            label: "SEO (Branding)",
            description:"Melhore sua visibilidade online e alcance o topo dos resultados de busca do Google de forma orgânica.",
            pricing:"300,00",
            time: "48h"
        },
        {
            label: "Trafego Pago",
            description:"Alcance e rastreie seu público onde ele estiver. Campanhas de Google Ads (anúncios de pesquisa, display, vídeo) Facebook Ads para promover produtos ou serviços.",
            pricing:"600,00",
            time: "72h"
        },
        {
            label: "implementação de Google meu negócio",
            description:"Esteja visível e alcance pessoas no google maps",
            pricing:"400,00",
            time: "72h"
        },
        {
            label: "Email Marketing",
            description:"Mantenha sempre seus clientes lembrando e comprando de você atraves de promoções enviadas na caixa de email.",
            pricing:"750,00",
            time: "72h"
        },
        {
            label: "Analise e implementação de CRM ",
            description:"Implementação de CRM com Integrações para automação de processos repetitivos e melhorar a comunicação e acompanhamento com os clientes.",
            pricing:"750,00",
            time: "72h"
        },
        {
            label: "Analise de Marketing",
            description:"Assessoria de Marketing para melhores conversões de leads",
            pricing:"750,00",
            time: "72h"
        },
        {
            label: "Sales Ops",
            description:"Assessoria estratégica para equipe de vendas e processo comercial para maior conversão de vendas ",
            pricing:"800,00",
            time: "72h"
        },
        {
            label: "Desenvolvimento de fluxo de Cadências",
            description:"Disparo de mensagens estrategia com gatilhos mentais para retomado de contato inativos e acompanhamento da jornada do cliente",
            pricing:"750,00",
            time: "72h"
        },
        {
            label: "Implementação de chatbot",
            description:"Automatização de pré atendimento com IA ou fluxo de chatbot",
            pricing:"800,00",
            time: "72h"
        },
        
        
    ]
    return (
        <>
            <section id="services" class="services" data-aos="fade-up">

                <div class="container">

                    <div class="section-title">
                        <h2>Nossos Serviços</h2>
                        <p>Leve sua empresa para o mercado digital com os melhores serviços. Impacte, e conquiste clientes no mundo inteiro de forma automatizada e atraente!</p>
                    </div>

                    <div class="row">
                       
                        {servicelist.map(({ label, description, pricing, time },key) => {
                           
                                        return (
                                            <div class="col-lg-4 col-md-6 d-flex align-items-stretch mb-5 mt-md-0">
                                            <div class="icon-box" key={servicelist.toString() } data-aos="zoom-in" data-aos-duration="400">
                                            <div class="icon"><i><img width="60" height="60" src="https://tvaebmtangjwdgtoxvdj.supabase.co/storage/v1/object/public/imagens/logo/favicon.png?t=2024-09-14T13%3A34%3A24.778Z"/></i></div>
                                            <h4><a href="">{label}</a></h4>
                                            <p>{description}</p>
                                            <p className="fs-3 my-2">Valor: <span className="fw-bold text-warning">R$ {pricing} </span> </p>
                                            <p className="fs-3">Horas: {time}</p>
                                            </div>
                                            </div>
                                        );
                             
                                    })}
                       
                    </div>

                </div>
            </section>
        </>
    )
}
