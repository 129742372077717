import { HashRouter, Routes, Route, BrowserRouter } from 'react-router-dom';

import Home from './pages/Home';
import ListaServicos from './pages/ListaServicos';
import Faq from './pages/FAQ/Faq';



function RoutesApp() {
    return (

        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/servicos" element={<ListaServicos/>} />
                <Route path="/faq" element={<Faq/>} />
            </Routes>
        </BrowserRouter>


    )

}

export default RoutesApp;