import RoutesApp from "./routes";
function App() {
  return (
    <>
          <RoutesApp />
    </>
  );
}

export default App;
