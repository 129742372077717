import ProAbout from "../../components/Abouts/ProAbout";
import ProFaq from "../../components/Accordion/ProFaq";
import Competencias from "../../components/Competencias";
import ProContact from "../../components/Contacts/ProContact";
import ProCta from "../../components/Cta/ProCta";
import ProFeatures from "../../components/Features/ProFeatures";
import ProFooter from "../../components/Footer/ProFooter";
import ProHeader from "../../components/Header/ProHeader";
import ProHero from "../../components/Heros/ProHero";
import Menu from "../../components/Menu/Menu";
import ProPrincing from "../../components/Pricings/ProPricing";
import ProServices from "../../components/Services/ProServices";
import './stylepromova.css'
export default function Promova() {
    return(
        <>
          <main id="main">
            <Menu/>
            <ProHero/>

            <ProAbout/>
            
            <Competencias/>

            <ProCta/>
            <ProFeatures/>
            <ProPrincing/>
            <ProContact/>
            <ProFooter/>
          </main>
        
        </>
    )
}